import React, { FC } from "react";

import ApiSectionProducts from "../../models/api/sections/sectionProducts.model";
import Container from "../Container";
import Typo from "../Typo";
import styles from "./SectionProducts.module.scss";
import ButtonCms from "../ButtonCms";
import ImageCustom from "../ImageCustom";
import { getMediaUrl } from "../../utils/api.utils";
import SectionBase from "./SectionBase";

export interface ISectionProductsProps extends ApiSectionProducts {}

const SectionProducts: FC<ISectionProductsProps> = (props) => {
  return (
    <Container className={styles.container}>
      <SectionBase>
        <Typo variant="h2" color="primary" className={styles.title}>
          {props.title}
        </Typo>
        <div className={styles.subContainer}>
          {props.products?.map((product) => (
            <div key={product.id} className={styles.product}>
              <div className={styles.imageWrapper}>
                {product.image.data?.attributes?.url && (
                  <ImageCustom
                    src={getMediaUrl(product.image.data?.attributes?.url)}
                    alt={product.image.data?.attributes?.alternativeText}
                    width={321}
                    height={178}
                    objectFit="contain"
                  />
                )}
              </div>
              <div className={styles.separator}></div>
              <div className={styles.descriptionWrapper}>
                <Typo color="dark">
                  <b>{product.title}</b>
                </Typo>
                {product.description && (
                  <Typo color="dark">
                    <span
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  </Typo>
                )}
              </div>
            </div>
          ))}
        </div>
        {props.button && (
          <ButtonCms {...props.button} className={styles.button} />
        )}
      </SectionBase>
    </Container>
  );
};

SectionProducts.defaultProps = {};

export default SectionProducts;
