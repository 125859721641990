import React, { FC } from "react";

import ApiSectionText from "../../models/api/sections/sectionText.model";
import ButtonCms from "../ButtonCms";
import Container from "../Container";
import Typo from "../Typo";
import SectionBase from "./SectionBase";
import styles from "./SectionText.module.scss";

export interface ISectionTextProps extends ApiSectionText {}

const SectionText: FC<ISectionTextProps> = (props) => {
  return (
    <SectionBase title={props.title} titlePosition="center">
      <Container className={styles.container}>
        <div className={styles.content}>
          <Typo>
            <span dangerouslySetInnerHTML={{ __html: props.description }} />
          </Typo>
        </div>
        {props.button && (
          <ButtonCms className={styles.button} {...props.button} />
        )}
      </Container>
    </SectionBase>
  );
};

SectionText.defaultProps = {};

export default SectionText;
