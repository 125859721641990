import React, { FC } from "react";

import ApiSectionService from "../../models/api/sections/sectionService.model";
import { getMediaUrl } from "../../utils/api.utils";
import Container from "../Container";
import ImageCustom from "../ImageCustom";
import Picto from "../Picto";
import Typo from "../Typo";
import SectionBase from "./SectionBase";
import styles from "./SectionService.module.scss";

export interface ISectionServiceProps extends ApiSectionService {}

const SectionService: FC<ISectionServiceProps> = (props) => {
  return (
    <SectionBase>
      <Container className={styles.container}>
        {props.image?.data?.attributes?.url && (
          <div className={styles.logo}>
            <ImageCustom
              src={getMediaUrl(props.image.data?.attributes?.url)}
              alt={props.image.data?.attributes?.alternativeText}
              width={320}
              height={180}
              objectFit="contain"
            />
          </div>
        )}
        <div className={styles.divider} />
        <div className={styles.serviceInfo}>
          <Typo variant="h3" color="primary">
            {props.title}
          </Typo>
          <Typo className={styles.serviceDescription}>{props.description}</Typo>
        </div>
        <div className={styles.related}>
          {props.userList && (
            <div className={styles.users}>
              <Typo variant="h4" color="primary" className={styles.userTitle}>
                {props.pictoTitle}
              </Typo>
              <div className={styles.userList}>
                {props.userList.map((user) => (
                  <Picto key={user.id} {...user} size="medium" />
                ))}
              </div>
            </div>
          )}
          <div className={styles.otherServices}>
            <Typo
              variant="h4"
              color="primary"
              className={styles.otherServicesTitle}
            >
              {props.serviceTitle}
            </Typo>
            {props.serviceList &&
              props.serviceList.map((service) => (
                <div key={service.id} className={styles.otherService}>
                  {service.image?.data?.attributes?.url && (
                    <div className={styles.otherServiceImage}>
                      <ImageCustom
                        src={getMediaUrl(service.image.data?.attributes?.url)}
                        alt={service.image.data?.attributes?.alternativeText}
                        width={200}
                        height={120}
                        objectFit="contain"
                      />
                    </div>
                  )}
                  <Typo
                    variant="h5"
                    color="secondary"
                    className={styles.otherServiceTitle}
                  >
                    {service.title}
                  </Typo>
                  <Typo className={styles.otherServiceDescription}>
                    {service.description}
                  </Typo>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.dates}>
          <Typo variant="h4" color="primary">
            {props.dateTitle}
          </Typo>
          {props.dateList &&
            props.dateList.map((date) => (
              <div key={date.id} className={styles.date}>
                <Typo
                  variant="h4"
                  color="secondary"
                  className={styles.dateLabel}
                >
                  {date.date}
                </Typo>
                <Typo>{date.description}</Typo>
              </div>
            ))}
        </div>
      </Container>
    </SectionBase>
  );
};

SectionService.defaultProps = {};

export default SectionService;
