import React, { FC } from "react";
import classNames from "classnames";

import ApiSectionTextAndImage from "../../models/api/sections/sectionTextAndImage.model";
import ApiMainPicture from "../../models/api/upload/picture.model";
import ButtonCms from "../ButtonCms";
import Container from "../Container";
import ImageCustom from "../ImageCustom";
import Typo from "../Typo";
import SectionBase from "./SectionBase";
import styles from "./SectionTextAndImage.module.scss";
import { getMediaUrl } from "../../utils/api.utils";

export interface ISectionTextAndImageProps extends ApiSectionTextAndImage {}

const SectionTextAndImage: FC<ISectionTextAndImageProps> = (props) => {
  // Width (px) the image can reach at is maximum
  const MAX_WIDTH = 870;

  const getDimensions = (image: ApiMainPicture) => {
    if (image) {
      const imageWidth = image.data?.attributes?.width;

      const imageHeight = image.data?.attributes?.height;

      const width = imageWidth > MAX_WIDTH ? MAX_WIDTH : imageWidth;

      const height =
        imageWidth > MAX_WIDTH
          ? (imageHeight * MAX_WIDTH) / imageWidth
          : imageHeight;

      return { width, height };
    } else return null;
  };
  const dimensions = getDimensions(props.image);

  return (
    <SectionBase>
      <Container className={classNames(styles.container)}>
        {dimensions && (
          <div
            className={classNames(
              styles.imageWrap,
              !props.imageLeft && styles.imageWrap_reverse
            )}
          >
            <ImageCustom
              src={getMediaUrl(props.image.data?.attributes?.url)}
              alt={props.image.data?.attributes?.alternativeText}
              width={dimensions.width}
              height={dimensions.height}
              objectFit="contain"
              objectPosition="center"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        )}
        <div
          className={classNames(
            styles.separator,
            !props.imageLeft && styles.separator_reverse
          )}
        />
        <div
          className={classNames(
            styles.content,
            !props.imageLeft && styles.content_reverse
          )}
        >
          <Typo variant="h3" color="primary">
            {props.title}
          </Typo>
          <Typo className={styles.body}>
            <span
              dangerouslySetInnerHTML={{ __html: props.description }}
              className={styles.decription}
            />
          </Typo>
          {props.button && (
            <ButtonCms {...props.button} className={styles.button} />
          )}
        </div>
      </Container>
    </SectionBase>
  );
};

SectionTextAndImage.defaultProps = {};

export default SectionTextAndImage;
