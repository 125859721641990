import React, { FC } from "react";
import Image from "next/image";
import classNames from "classnames";

import ApiSectionKeyNumbers from "../../models/api/sections/sectionKeyNumbers.model";
import ButtonCms from "../ButtonCms";
import KeyNumber from "../KeyNumber";
import Typo from "../Typo";
import SectionBase from "./SectionBase";
import styles from "./SectionKeyNumbers.module.scss";

export interface ISectionKeyNumbersProps extends ApiSectionKeyNumbers {}

const SectionKeyNumbers: FC<ISectionKeyNumbersProps> = (props) => {
  // Back-end allow only 3 or 4 keynumbers, not more, not less.
  const amountOfKeyNumbers = props.keyNumbers.length;
  return (
    <SectionBase
      title={props.title}
      titlePosition="center"
      className={classNames(styles.container, styles.container_alt)}
      character="2"
      characterPosition="left"
    >
      <div className={styles.backgroundContainer}>
        <Image
          src={"/vector/key-number-background.svg"}
          alt=""
          width={438}
          height={438}
        />
        <Typo variant="h4" color="secondary" className={styles.description}>
          {props.description}
        </Typo>
        <div className={styles.keyContainers}>
          <div
            className={classNames(
              styles.keyNumbers,
              amountOfKeyNumbers > 3 && styles.keyNumbers_alt
            )}
          >
            {props.keyNumbers.map((keyNumber, index) => (
              <KeyNumber key={`${keyNumber}-${index}`} {...keyNumber} />
            ))}
          </div>
        </div>
      </div>
      {props.button && (
        <div
          className={classNames(
            styles.buttonContainer,
            amountOfKeyNumbers > 3 && styles.buttonContainer_alt
          )}
        >
          <ButtonCms color="secondary" {...props.button} />
        </div>
      )}
    </SectionBase>
  );
};

SectionKeyNumbers.defaultProps = {};

export default SectionKeyNumbers;
