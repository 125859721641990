export function getYoutubeEmbedLink(videoUrl: string) {
  if (typeof videoUrl !== "string") {
    return undefined;
  }

  const id = getYoutubeVideoId(videoUrl);

  if (id) {
    return "//www.youtube.com/embed/" + id;
  }
}

export function getYoutubeVideoId(videoUrl: string) {
  if (typeof videoUrl !== "string") {
    return undefined;
  }
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = videoUrl.match(regExp);

  if (match && match[2].length == 11) {
    return match[2];
  }
}
