import React, { FC } from "react";
import classNames from "classnames";

import { getMediaUrl } from "../utils/api.utils";
import styles from "./EditoAuthor.module.scss";
import ImageCustom from "./ImageCustom";
import Typo from "./Typo";

export interface IEditoAuthorProps {
  name: string;
  job: string;
  imageSrc?: string;
  className?: string;
  reverse: boolean;
}

const EditoAuthor: FC<IEditoAuthorProps> = (props) => {
  return (
    <div
      className={classNames(
        styles.author,
        props.reverse && styles.author_reverse,
        props.className
      )}
    >
      <div className={styles.imageWrap}>
        <ImageCustom
          src={
            props.imageSrc ? getMediaUrl(props.imageSrc) : "/placeholder.jpg"
          }
          width={200}
          height={200}
          alt={props.name}
          objectFit="cover"
          className={styles.image}
        />
      </div>
      <div className={styles.info}>
        <Typo
          variant="h5"
          component="span"
          color="primary"
          className={styles.name}
        >
          {props.name}
        </Typo>
        <Typo
          variant="body"
          component="span"
          color="primary"
          className={styles.job}
        >
          {props.job}
        </Typo>
      </div>
    </div>
  );
};

EditoAuthor.defaultProps = {};

export default EditoAuthor;
