import ApiComponent from "../component.model";

export default interface ApiSectionMap extends ApiComponent {
  __component: "section.map";
  title?: string;
  description: string;
  color: "bleu" | "turquoise" | "vert" | "rouge";
  unit: string;
  separators: {
    id: number;
    value: number;
  }[];
  ariege: number;
  aude: number;
  aveyron: number;
  gard: number;
  gers: number;
  hautesPyrenees: number;
  hauteGaronne: number;
  herault: number;
  lot: number;
  lozere: number;
  pyreneesOrientales: number;
  tarn: number;
  tarnEtGaronne: number;
}

export type ApiSectionMapZone = keyof Pick<
  ApiSectionMap,
  | "ariege"
  | "aude"
  | "aveyron"
  | "gard"
  | "gers"
  | "hautesPyrenees"
  | "hauteGaronne"
  | "herault"
  | "lot"
  | "lozere"
  | "pyreneesOrientales"
  | "tarn"
  | "tarnEtGaronne"
>;

export const MAP_ZONES: ApiSectionMapZone[] = [
  "ariege",
  "aude",
  "aveyron",
  "gard",
  "gers",
  "hautesPyrenees",
  "hauteGaronne",
  "herault",
  "lot",
  "lozere",
  "pyreneesOrientales",
  "tarn",
  "tarnEtGaronne",
];
