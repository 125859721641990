import ApiSectionMap, {
  ApiSectionMapZone,
} from "../models/api/sections/sectionMap.model";
import ApiSectionMapFree from "../models/api/sections/sectionMapFree";

export function getZoneValue(zone: ApiSectionMapZone, data: ApiSectionMap) {
  const value = data[zone];

  return `${value} ${data.unit}`;
}
export function getZoneColor(zone: ApiSectionMapZone, data: ApiSectionMap) {
  const value = data[zone];

  if (!value) {
    return "color-base";
  } else if (value < data.separators[0]?.value) {
    return "lvl1";
  } else if (value < data.separators[1]?.value) {
    return "lvl2";
  } else if (value < data.separators[2]?.value) {
    return "lvl3";
  } else {
    return "lvl4";
  }
}

export function getMapAutoLegendLabel(index: number, data: ApiSectionMap) {
  if (index === 0) {
    return `Inférieur à ${data.separators[index].value} ${data.unit}`;
  } else if (index === data.separators.length) {
    return `Supérieur à ${data.separators[index - 1].value} ${data.unit}`;
  } else {
    return `Entre ${data.separators[index - 1].value} et ${
      data.separators[index].value
    } ${data.unit}`;
  }
}

export function getMapFreeLegendLabel(index: number, data: ApiSectionMapFree) {
  return data.legend[index].value;
}
