import React, { FC } from "react";

import ApiKeyNumber from "../models/api/utils/keyNumber.model";
import styles from "./KeyNumber.module.scss";
import Typo from "./Typo";

export interface IKeyNumberProps extends ApiKeyNumber {}

const KeyNumber: FC<IKeyNumberProps> = (props) => {
  return (
    <div className={styles.container}>
      <Typo variant="h3" color="light" className={styles.keyNumber}>
        {props.keyNumber}
      </Typo>
      <Typo color="light" className={styles.description}>
        {props.description}
      </Typo>
    </div>
  );
};

KeyNumber.defaultProps = {};

export default KeyNumber;
