import classNames from "classnames";
import Image from "next/image";
import React, { FC } from "react";
import ApiSectionSpacer from "../models/api/sections/sectionSpacer.model";
import ImageCustom from "./ImageCustom";
import styles from "./Spacer.module.scss";

export interface ISpacerProps extends ApiSectionSpacer {}

const Spacer: FC<ISpacerProps> = (props) => {
  return (
    <div className={classNames(styles.container, styles[`${props.spacers}`])}>
      <ImageCustom
        src={`/vector/spacers/${props.spacers}.svg`}
        alt=""
        layout="fill"
      />
    </div>
  );
};

Spacer.defaultProps = {};

export default Spacer;
