import classNames from "classnames";
import React, { FC } from "react";

import ApiSectionHero from "../../models/api/sections/sectionHero.model";
import Container from "../Container";
import styles from "./SectionHero.module.scss";
import Typo from "../Typo";
import ApiMainPicture from "../../models/api/upload/picture.model";
import CTA from "../CTA";
import ButtonCms from "../ButtonCms";
import ImageCustom from "../ImageCustom";
import { getMediaUrl } from "../../utils/api.utils";
import SectionBase from "./SectionBase";

export interface ISectionHeroProps extends ApiSectionHero {}

const SectionHero: FC<ISectionHeroProps> = (props) => {
  let images: ApiMainPicture[] = [];

  if (props.image1?.data) {
    images.push(props.image1);
  }
  if (props.image2?.data) {
    images.push(props.image2);
  }
  if (props.image3?.data) {
    images.push(props.image3);
  }

  const numberOfImages = images.length;
  let imageCase = "primary";

  if (numberOfImages === 2) {
    imageCase = "secondary";
  } else if (numberOfImages === 3) {
    imageCase = "tertiary";
  }

  return (
    <SectionBase decorator="1" className={styles.sectionBase}>
      <Container className={styles.gridContainer}>
        {images && numberOfImages > 0 && (
          <div className={styles.imageContainer}>
            <ImageCustom src="/vector/blue-patch.svg" layout="fill" alt="" />
            {images.map((image, index) => (
              <div
                key={`image-${index}`}
                className={classNames(
                  styles.image,
                  styles[`image_${imageCase}_${index + 1}`]
                )}
              >
                <ImageCustom
                  src={getMediaUrl(image.data?.attributes?.url)}
                  alt={image.data?.attributes?.alternativeText}
                  width={300}
                  height={300}
                  objectFit="cover"
                />
              </div>
            ))}
          </div>
        )}
        <div className={styles.contentContainer}>
          <div className={styles.headlineContainer}>
            <Typo variant="h3" color="secondary" className={styles.subtitle}>
              {props.subtitle}
            </Typo>
            <Typo
              variant="headline"
              color="secondary"
              className={styles.headline}
            >
              {props.headline}
            </Typo>
            <Typo
              variant="h1"
              component="h1"
              color="primary"
              className={styles.title}
            >
              {props.title}
            </Typo>
          </div>
          {props.content && (
            <Typo color="dark">
              <span dangerouslySetInnerHTML={{ __html: props.content }} />
            </Typo>
          )}
          {props.type === "telechargementRapport" ? (
            <CTA
              className={styles.cta}
              text="Télécharger dans son intégralité le rapport d'activité en version pdf"
              color="primary"
              href="/"
            />
          ) : (
            props.type === "lien" &&
            props.button && (
              <ButtonCms {...props.button} className={styles.button} />
            )
          )}
        </div>
      </Container>
    </SectionBase>
  );
};

SectionHero.defaultProps = {};

export default SectionHero;
