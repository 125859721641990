import React, { FC, useRef, useState } from "react";
import classNames from "classnames";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useMedia } from "react-use";

import ApiFaqItem from "../models/api/utils/faqItem.model";
import styles from "./FAQItem.module.scss";
import Typo from "./Typo";
import { useEffect } from "react";

export interface IFAQItemProps {
  data: ApiFaqItem;
}

const FAQItem: FC<IFAQItemProps> = (props) => {
  const isPrint = useMedia("print", false);

  const [isAnswerDisplayed, setIsAnswerDisplayed] = useState<boolean>(false);

  useEffect(() => {
    if (!isAnswerDisplayed && isPrint) {
      handleClick();
    }
  });

  const refAnswer = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (refAnswer.current) {
      if (isAnswerDisplayed) {
        refAnswer.current.style.maxHeight = "0";
      } else {
        refAnswer.current.style.maxHeight =
          refAnswer.current.scrollHeight + "px";
      }
    }
    setIsAnswerDisplayed(!isAnswerDisplayed);
  };

  return (
    <div className={classNames(styles.container)}>
      <div
        className={classNames(
          styles.questionContainer,
          isAnswerDisplayed && styles.questionContainer_display
        )}
      >
        <div className={styles.question}>
          <Typo variant="h3">{props.data.question}</Typo>
        </div>
        <button className={styles.button} onClick={handleClick}>
          {isAnswerDisplayed ? (
            <Typo variant="body">Masquer</Typo>
          ) : (
            <Typo variant="body">Afficher</Typo>
          )}
          <div
            className={classNames(
              styles.iconContainer,
              isAnswerDisplayed && styles.iconContainer_display
            )}
          >
            {isAnswerDisplayed ? (
              <IoIosArrowUp className={styles.icon} />
            ) : (
              <IoIosArrowDown className={styles.icon} />
            )}
          </div>
        </button>
      </div>
      <div
        className={classNames(
          styles.answer,
          isAnswerDisplayed && styles.answer_display
        )}
        ref={refAnswer}
      >
        <Typo>{props.data.answer}</Typo>
      </div>
    </div>
  );
};

FAQItem.defaultProps = {};

export default FAQItem;
