import ApiColor from "../models/api/colors.model";

//TODO: merge both functions
export function getVariant(color: ApiColor["color"]) {
  switch (color) {
    case "jaune":
      return "variant1";
    case "orange":
      return "variant2";
    case "rose":
      return "variant3";
    case "vert":
      return "variant4";
    case "bleu":
      return "primary";
  }
}

export function getButtonVariant(color: ApiColor["color"]) {
  switch (color) {
    case "jaune":
      return "variant-1";
    case "orange":
      return "variant-1-dark";
    case "rose":
      return "variant-2";
    case "vert":
      return "variant-3";
    case "bleu":
      return "primary";
  }
}
