import React, { FC } from "react";
import classNames from "classnames";
import ApiSectionYoutubeVideo from "../../models/api/sections/sectionYoutubeVideo.model";
import { getYoutubeVideoId } from "../../utils/youtube";
import Container from "../Container";
import ImageCustom from "../ImageCustom";
import Typo from "../Typo";
import SectionBase from "./SectionBase";
import styles from "./SectionYoutubeVideo.module.scss";

export interface ISectionYoutubeVideoProps extends ApiSectionYoutubeVideo {}

const SectionYoutubeVideo: FC<ISectionYoutubeVideoProps> = (props) => {
  return (
    <SectionBase title={props.title} titlePosition="center">
      <Container className={styles.container}>
        <div className={styles.decorator}>
          <ImageCustom
            src="/vector/video-decorator.svg"
            width={966}
            height={602}
            alt=""
          />
        </div>
        <div className={styles.videoContainer}>
          <div
            className={classNames("youtube_player", styles.iframe)}
            // @ts-ignore
            videoID={getYoutubeVideoId(props.url)}
          ></div>
          <div className={styles.videoLink}>
            <Typo color="light">
              Lien vers la vidéo : <br /> <a href={props.url}>{props.url}</a>
            </Typo>
          </div>
        </div>
        {props.description && (
          <div className={styles.description}>
            <Typo>{props.description}</Typo>
          </div>
        )}
      </Container>
    </SectionBase>
  );
};

SectionYoutubeVideo.defaultProps = {};

export default SectionYoutubeVideo;
