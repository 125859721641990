import React, { FC } from "react";

import ApiSectionTimeline from "../../models/api/sections/SectionTimeline.model";
import Container from "../Container";
import Typo from "../Typo";
import SectionBase from "./SectionBase";
import styles from "./SectionTimeline.module.scss";

export interface ISectionTimelineProps extends ApiSectionTimeline {}

const SectionTimeline: FC<ISectionTimelineProps> = (props) => {
  return (
    <SectionBase title={props.title}>
      <Container className={styles.container}>
        {props.dates.map((date, index) => (
          <div key={date.id} className={styles.date}>
            <Typo
              className={styles.dateValue}
              variant="h1"
              color={index % 2 ? "primary" : "secondary"}
            >
              {date.date}
            </Typo>
            {date.description && (
              <Typo className={styles.dateDescription}>
                <span dangerouslySetInnerHTML={{ __html: date.description }} />
              </Typo>
            )}
          </div>
        ))}
      </Container>
    </SectionBase>
  );
};

SectionTimeline.defaultProps = {};

export default SectionTimeline;
