import React, { FC } from "react";

import ApiSectionPictos from "../../models/api/sections/sectionPictos.model";
import ButtonCms from "../ButtonCms";
import Container from "../Container";
import Picto from "../Picto";
import SectionBase from "./SectionBase";
import styles from "./SectionPictos.module.scss";

export interface ISectionPictosProps extends ApiSectionPictos {}

const SectionPictos: FC<ISectionPictosProps> = (props) => {
  return (
    <SectionBase
      title={props.title}
      character="4"
      characterPosition="right"
      titlePosition="center"
    >
      <Container>
        <div className={styles.container}>
          {props.pictos.map((picto, index) => (
            <Picto
              key={index}
              label={picto.label}
              image={picto.image}
              size="medium"
            />
          ))}
        </div>
        {props.button && (
          <div className={styles.buttonContainer}>
            <ButtonCms {...props.button} />
          </div>
        )}
      </Container>
    </SectionBase>
  );
};

SectionPictos.defaultProps = {};

export default SectionPictos;
