import React, { FC } from "react";
import classNames from "classnames";

import ApiCard from "../models/api/utils/card.model";
import { getMediaUrl } from "../utils/api.utils";
import { getButtonVariant, getVariant } from "../utils/color.utils";
import Button from "./Button";
import styles from "./Card.module.scss";
import ImageCustom from "./ImageCustom";
import Typo from "./Typo";

export interface ICardProps extends ApiCard {}

const Card: FC<ICardProps> = (props) => {
  return (
    <div
      className={classNames(
        styles.container,
        styles[`container_${getVariant(props.color)}`]
      )}
    >
      <div
        className={classNames(
          styles.image,
          styles[`image_${getVariant(props.color)}`]
        )}
      >
        <ImageCustom
          src={getMediaUrl(props.image.data?.attributes?.url)}
          alt={props.image.data?.attributes?.alternativeText}
          width={321}
          height={180}
          objectFit="cover"
        />
      </div>
      <Typo component="h3" className={styles.title}>
        {props.title}
      </Typo>
      <div className={styles.buttonContainer}>
        <Button
          color={getButtonVariant(props.color) as any}
          text="Accéder à ce contenu"
          href={props.url}
          isExternal={props.isExternal}
        />
      </div>
    </div>
  );
};

Card.defaultProps = {};

export default Card;
