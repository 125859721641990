import React, { FC } from "react";

import ApiButton from "../models/api/utils/button.model";
import Button, { IButtonProps } from "./Button";
import styles from "./ButtonCms.module.scss";

export interface IButtonCmsProps
  extends ApiButton,
    Pick<IButtonProps, "color"> {
  className?: string;
}

const ButtonCms: FC<IButtonCmsProps> = (props) => {
  return (
    <Button
      color={props.color}
      className={props.className}
      text={props.label}
      href={props.url}
      isExternal={props.isExternal}
    />
  );
};

ButtonCms.defaultProps = {};

export default ButtonCms;
