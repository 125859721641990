import React, { FC } from "react";

import ApiSectionTiles from "../../models/api/sections/sectionTiles.model";
import { getMediaUrl } from "../../utils/api.utils";
import ButtonCms from "../ButtonCms";
import Container from "../Container";
import ImageCustom from "../ImageCustom";
import Typo from "../Typo";
import SectionBase from "./SectionBase";
import styles from "./SectionTiles.module.scss";

export interface ISectionTilesProps extends ApiSectionTiles {}

const SectionTiles: FC<ISectionTilesProps> = (props) => {
  return (
    <SectionBase title={props.title} titlePosition="center">
      <Container className={styles.container}>
        {props.items.map((tile) => (
          <div key={tile.id} className={styles.tile}>
            <div className={styles.image}>
              <ImageCustom
                src={getMediaUrl(tile.image.data?.attributes?.url)}
                alt={tile.image.data?.attributes?.alternativeText}
                width={100}
                height={100}
                objectFit="contain"
              />
            </div>
            <Typo variant="h3" color="primary" className={styles.title}>
              {tile.title}
            </Typo>
            <Typo className={styles.description}>{tile.description}</Typo>
            {tile.showButton && (
              <ButtonCms
                isExternal={tile.isExternal!}
                label={tile.buttonLabel ?? ""}
                url={tile.url ?? ""}
              />
            )}
          </div>
        ))}
      </Container>
    </SectionBase>
  );
};

SectionTiles.defaultProps = {};

export default SectionTiles;
