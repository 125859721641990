import React, { FC } from "react";
import classNames from "classnames";
import jsonexport from "jsonexport/dist";

import ApiSectionMap, {
  MAP_ZONES,
} from "../../models/api/sections/sectionMap.model";
import Container from "../Container";
import SvgMap from "../SvgMap";
import Typo from "../Typo";
import SectionBase from "./SectionBase";
import styles from "./SectionMap.module.scss";
import { snakeCase } from "../../utils/string.utils";
import {
  getMapAutoLegendLabel,
  getMapFreeLegendLabel,
  getZoneColor,
  getZoneValue,
} from "../../utils/map.utils";
import ApiSectionMapFree from "../../models/api/sections/sectionMapFree";

export interface ISectionMapAutoProps extends ApiSectionMap {}

export interface ISectionMapFreeProps extends ApiSectionMapFree {}

export type ISectionMapProps = ISectionMapAutoProps | ISectionMapFreeProps;

// TODO finish section responsive when layout is done
const SectionMap: FC<ISectionMapProps> = (props) => {
  /**
   * Export JSON to CSV using "jsonexport" library,
   * then trigger the download of the csv file
   */
  async function downloadCsv() {
    jsonexport(
      MAP_ZONES.map((zone) => {
        if (props.__component === "section.map") {
          return {
            departement: zone,
            [`valeur (${props.unit})`]: props[zone]?.toString(),
          };
        } else if (props.__component === "section.map-free") {
          return {
            departement: zone,
            [`valeur`]: props[zone],
          };
        }
      }),
      {
        rowDelimiter: ";", // default excel delimiter
      },
      (err, csv) => {
        if (err) return console.error(err);
        const downloadElement = document.createElement("a");

        // add '\ufeff' to the start of the csv to make it compatible with Excel
        // https://github.com/kaue/jsonexport/issues/29
        downloadElement.href =
          "data:text/csv;charset=utf-8," + encodeURI("\ufeff" + csv);

        downloadElement.target = "_blank";
        downloadElement.download = props.title
          ? `esante_${snakeCase(props.title)}_${Date.now()}.csv`
          : `esante_${Date.now()}.csv`;
        downloadElement.click();
      }
    );
  }

  const legendLength =
    props.__component === "section.map"
      ? props.separators?.length
      : props.legend?.length;

  return (
    <SectionBase title={props.title} className={styles[`color-${props.color}`]}>
      <div className={styles.wrap}>
        <Container className={styles.container}>
          <SvgMap
            className={styles.map}
            getZoneValue={(zone) =>
              props.__component === "section.map"
                ? getZoneValue(zone, props)
                : props[zone]
            }
            getZoneColor={(zone) =>
              props.__component === "section.map"
                ? getZoneColor(zone, props)
                : // @ts-ignore
                  `lvl${props[zone + "Color"]}`
            }
          />
          <div className={styles.contentContainer}>
            <div className={styles.content}>
              <Typo
                variant="h3"
                component="h3"
                color="primary"
                className={styles.title}
              >
                {props.title}
              </Typo>
              <Typo component="p" className={styles.description}>
                {props.description}
              </Typo>
              {legendLength > 0 && (
                <div className={styles.legend}>
                  {Array(legendLength)
                    .fill(0)
                    .map((v, index) => (
                      <div
                        key={`separator-${index}`}
                        className={styles.legendItem}
                      >
                        <div
                          className={classNames(
                            styles.legendColor,
                            styles[`legendColor_lvl${index + 1}`]
                          )}
                        />
                        <Typo color="dark" className={styles.legendLabel}>
                          <strong>
                            {props.__component === "section.map"
                              ? getMapAutoLegendLabel(index, props)
                              : getMapFreeLegendLabel(index, props)}
                          </strong>
                        </Typo>
                      </div>
                    ))}
                </div>
              )}
              <Typo color="dark">
                {/* TODO "a" should be reserved for navigation, but this is not 
                a good practice to make a button look like a link... */}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    downloadCsv();
                  }}
                >
                  Visualiser les données de la carte au format CSV
                </a>
              </Typo>
            </div>
          </div>
        </Container>
      </div>
    </SectionBase>
  );
};

SectionMap.defaultProps = {};

export default SectionMap;
