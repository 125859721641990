import React, { FC } from "react";
import ApiSectionFAQ from "../../models/api/sections/sectionFaq.model";
import Container from "../Container";
import FAQItem from "../FAQItem";
import ImageCustom from "../ImageCustom";
import SectionBase from "./SectionBase";
import styles from "./SectionFAQ.module.scss";

export interface ISectionFAQProps {
  data: ApiSectionFAQ;
}

const SectionFAQ: FC<ISectionFAQProps> = (props) => {
  return (
    <SectionBase
      title={props.data.title}
      background="secondary"
      titlePosition="padding-left"
      titleStyle="secondary"
      titleClass={styles.title}
      character="4"
      characterPosition="left"
      decorator="3"
      className={styles.baseContainer}
    >
      <div className={styles.picto}>
        <ImageCustom
          src={"/vector/picto-question-mark.svg"}
          height={200}
          width={200}
          alt=""
        />
      </div>
      <Container className={styles.container}>
        <div className={styles.content}>
          {props.data.list.map((item, index) => (
            <FAQItem key={`faq-item-${index}`} data={item} />
          ))}
        </div>
      </Container>
    </SectionBase>
  );
};

SectionFAQ.defaultProps = {};

export default SectionFAQ;
