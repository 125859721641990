import React, { FC } from "react";
import classNames from "classnames";
import { RiDoubleQuotesL } from "react-icons/ri";

import ApiSectionEdito from "../../models/api/sections/sectionEdito.model";
import Container from "../Container";
import EditoAuthor from "../EditoAuthor";
import Typo from "../Typo";
import SectionBase from "./SectionBase";
import styles from "./SectionEdito.module.scss";

export interface ISectionEditoProps extends ApiSectionEdito {}

const SectionEdito: FC<ISectionEditoProps> = (props) => {
  return (
    <SectionBase title={props.title}>
      {props.editos.map((edito, index) => (
        <Container
          className={classNames(
            styles.edito,
            index % 2 ? styles.edito_odd : styles.edito_even
          )}
          key={index}
        >
          <div className={styles.authors}>
            <EditoAuthor
              name={edito.author1}
              job={edito.job1}
              imageSrc={edito.image1?.data?.attributes?.url}
              reverse={!!(index % 2)}
            />
            {edito.author2 && edito.job2 && edito.image2 && (
              <EditoAuthor
                name={edito.author2}
                job={edito.job2}
                imageSrc={edito.image2.data?.attributes?.url}
                reverse={!!(index % 2)}
              />
            )}
          </div>
          <div className={styles.separator} />
          <div
            className={classNames(
              styles.content,
              styles[`content_${index + 1}`]
            )}
          >
            <RiDoubleQuotesL className={styles.iconQuotes} />
            <div className={styles.body}>
              <Typo color="dark">
                <i>
                  <div dangerouslySetInnerHTML={{ __html: edito.text }} />
                </i>
              </Typo>
            </div>
          </div>
        </Container>
      ))}
    </SectionBase>
  );
};

SectionEdito.defaultProps = {};

export default SectionEdito;
