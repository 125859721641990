import React, { FC } from "react";

import ApiSectionCardList from "../../models/api/sections/sectionCardList.model";
import Card from "../Card";
import Container from "../Container";
import SectionBase from "./SectionBase";
import styles from "./SectionCardList.module.scss";

export interface ISectionCardListProps extends ApiSectionCardList {}

const SectionCardList: FC<ISectionCardListProps> = (props) => {
  return (
    <SectionBase title={props.title}>
      <Container className={styles.cardContainer}>
        {props.cardList.map((card, index) => (
          <Card key={card.id} {...card} />
        ))}
      </Container>
    </SectionBase>
  );
};

SectionCardList.defaultProps = {};

export default SectionCardList;
