import React, { FC } from "react";
import Image from "next/image";

import ApiSectionKeyNumbersAlt from "../../models/api/sections/sectionKeyNumbersAlt.model";
import SectionBase from "./SectionBase";
import styles from "./SectionKeyNumbersAlt.module.scss";
import Typo from "../Typo";
import Container from "../Container";

export interface ISectionKeyNumbersAltProps extends ApiSectionKeyNumbersAlt {}

const SectionKeyNumbersAlt: FC<ISectionKeyNumbersAltProps> = (props) => {
  return (
    <SectionBase title={props.title} titlePosition="center">
      <Container className={styles.container}>
        <div className={styles.mainKeyNumberContainer}>
          <div className={styles.decorator}>
            <Image
              src="/vector/key-alt-decorator.svg"
              width={364}
              height={227}
              alt=""
            />
          </div>
          <div className={styles.content}>
            <Typo
              variant="h1"
              color="secondary"
              className={styles.mainKeyNumber}
            >
              {props.mainKeynumber}
            </Typo>
            <Typo
              variant="h4"
              color="primary"
              className={styles.mainKeyNumberDescription}
            >
              {props.description}
            </Typo>
          </div>
        </div>
        <div className={styles.altKeyNumbersContainer}>
          {props.keyNumbers.map((KeyNumber, index) => (
            <div key={`keyNumber-${index}`} className={styles.keyNumber}>
              <Typo
                variant="h1"
                color="primary"
                className={styles.keyNumberValue}
              >
                {KeyNumber.keyNumber}
              </Typo>
              <Typo>{KeyNumber.description}</Typo>
            </div>
          ))}
        </div>
      </Container>
    </SectionBase>
  );
};

SectionKeyNumbersAlt.defaultProps = {};

export default SectionKeyNumbersAlt;
