import React, { FC } from "react";

import ApiSectionPoles from "../../models/api/sections/sectionPoles.model";
import Container from "../Container";
import SectionBase from "./SectionBase";
import styles from "./SectionPoles.module.scss";
import Typo from "../Typo";
import ButtonCms from "../ButtonCms";
import { getMediaUrl } from "../../utils/api.utils";
import ImageCustom from "../ImageCustom";

export interface ISectionPolesProps extends ApiSectionPoles {}

const SectionPoles: FC<ISectionPolesProps> = (props) => {
  return (
    <SectionBase title={props.title} titlePosition="center">
      <Container className={styles.container}>
        <div className={styles.poles}>
          {props.poles.map((pole) => (
            <div key={pole.id} className={styles.pole}>
              <div className={styles.image}>
                <ImageCustom
                  src={
                    pole.image.data?.attributes?.url
                      ? getMediaUrl(pole.image.data?.attributes?.url)
                      : "/placeholder.jpg"
                  }
                  alt={
                    pole.image.data?.attributes?.url
                      ? pole.image.data?.attributes?.alternativeText
                      : ""
                  }
                  width={280}
                  height={176}
                  objectFit="cover"
                />
              </div>
              <Typo variant="h4" color="secondary">
                {pole.title}
              </Typo>
              <div className={styles.divider} />
              <Typo>{pole.description}</Typo>
            </div>
          ))}
        </div>
        {props.button && <ButtonCms {...props.button} color="secondary" />}
      </Container>
    </SectionBase>
  );
};

SectionPoles.defaultProps = {};

export default SectionPoles;
