import classNames from "classnames";
import React, { FC } from "react";

import ApiPicto from "../models/api/utils/picto.model";
import { getMediaUrl } from "../utils/api.utils";
import ImageCustom from "./ImageCustom";
import styles from "./Picto.module.scss";
import Typo from "./Typo";

export interface IPictoProps extends ApiPicto {
  size: "medium" | "large";
}

const Picto: FC<IPictoProps> = (props) => {
  const imageSize = props.size === "medium" ? 80 : 100;
  return (
    <div
      className={classNames(
        styles.container,
        styles[`container_image-${props.size}`]
      )}
    >
      <div className={styles.image}>
        <ImageCustom
          src={getMediaUrl(props.image.data?.attributes?.url)}
          width={imageSize}
          height={imageSize}
          alt={props.image.data?.attributes?.alternativeText}
          objectFit="contain"
        />
      </div>
      <div className={styles.labelWrap}>
        <Typo
          variant={props.size === "medium" ? "button" : "h5"}
          color="secondary"
          className={styles.label}
        >
          {props.label}
        </Typo>
      </div>
    </div>
  );
};

Picto.defaultProps = {};

export default Picto;
