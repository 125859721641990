import classNames from "classnames";
import React, { FC } from "react";
import ApiSectionImage from "../../models/api/sections/sectionImage.model";
import { getMediaUrl } from "../../utils/api.utils";
import ButtonCms from "../ButtonCms";
import Container from "../Container";
import ImageCustom from "../ImageCustom";
import Typo from "../Typo";
import SectionBase from "./SectionBase";
import styles from "./SectionImage.module.scss";

export interface ISectionImageProps extends ApiSectionImage {}

const SectionImage: FC<ISectionImageProps> = (props) => {
  const isImage2 = !!props.image2?.data;
  return (
    <SectionBase title={props.title} titlePosition="center">
      <Container
        className={classNames(
          styles.container,
          props.image2 && styles.container_alt
        )}
      >
        <div
          className={classNames(
            styles.decorator,
            isImage2 && styles.decorator_alt
          )}
        >
          <ImageCustom
            src="/vector/video-decorator.svg"
            alt=""
            layout="fill"
            objectPosition="center"
          />
        </div>
        <div
          className={classNames(
            styles.imageContainer,
            isImage2 && styles.imageContainer_alt
          )}
        >
          <div
            className={isImage2 ? styles.imageWrapper_alt : styles.imageWrapper}
          >
            <ImageCustom
              src={getMediaUrl(props.image1.data?.attributes?.url)}
              alt=""
              objectFit="contain"
              width={isImage2 ? 656 : 906}
              height={510}
            />
          </div>
          {isImage2 && (
            <div className={styles.imageWrapper}>
              <ImageCustom
                src={getMediaUrl(props.image2!.data?.attributes?.url)}
                alt=""
                objectFit="contain"
                width={656}
                height={510}
              />
            </div>
          )}
        </div>
      </Container>
      <Container>
        {props.description && (
          <div className={styles.description}>
            <Typo color="dark" className={styles.description}>
              <span dangerouslySetInnerHTML={{ __html: props.description }} />
            </Typo>
          </div>
        )}
        {props.button && (
          <div className={styles.button}>
            <ButtonCms {...props.button} />
          </div>
        )}
      </Container>
    </SectionBase>
  );
};

SectionImage.defaultProps = {};

export default SectionImage;
